import Vue from "vue"
import jquery3 from "jquery";

window.addEventListener('load', () => {
  var element = document.getElementById("new-survey-send")
  if (element != null) {
    var vm = new Vue({
      el: element,
      data: {
        selectedSurvey: "",
        surveys: [],
        surveysLoaded: false,
      },
      mounted: function() {
        jquery3.getJSON( "/surveys.json", function( data ) {
          vm.surveys = data;
          if(data.length > 0) {
            vm.selectedSurvey = data[0].id;
          }
          vm.surveysLoaded = true;
        });
      }
    });
  }
});
